<template>
  <div class="QueryRegion">
    <div class="QueryRegion_h">
      <div class="QueryRegion_bac"></div>
      <div class="QueryRegion_til">
        <img src="@/assets/img/query/query_til.png" alt="" />
      </div>
      <div class="QueryRegion_box">
        <div class="QueryRegion_content">
          <van-search v-model.trim="keywords" placeholder="请输入关键字搜索" maxlength="200" size="large" @blur="handleSearch">
            <template #left-icon>
              <van-icon name="search" size="24px" />
            </template>
          </van-search>
        </div>
        <div class="QueryRegion_data">
          <van-collapse v-model="tabActive" ref="collapseList">
            <van-collapse-item v-for="item in policyListFilter" :name="item.typeName" :key="item.typeName" size="large" :border="false">
              <template #title>
                <div class="collapse-item-title">
                  <van-image class="van-cell__left-icon" width="22" height="22" :src="imgList[item.iconName]" />
                  <span>{{ item.typeName }}</span>
                </div>
              </template>
              <template #right-icon>
                <van-icon name="play" class="van-cell__right-icon" color="#1989fa" size="18px" />
              </template>
              <van-cell v-for="subItem in item.list" is-link :key="item.id" @click="getDetail(subItem, item.typeName)">
                <template #title>
                  <div v-if="subItem.id == id && !isSearch" style="opacity: 0.5">{{ subItem.title }}</div>
                  <div v-html="subItem.title" v-else></div>
                </template>
              </van-cell>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <copyrightIp></copyrightIp>
    <!-- 查询超限提示 -->
    <PopupTips :show.sync="showLimitQuery">
      <p>
        您今日或本月查询次数超限，如需相关服务，请咨询公司的<span style="color: #1789f9">客服专员</span>或在公众号<span style="color: #1789f9">回复【合作】</span>留言
      </p>
    </PopupTips>
  </div>
</template>

<script>
    import { getSalaryResult, getWelfareDetail, postLog } from "@/api/salaryQuery";
    import { validatenull } from "@/utils/validate";
    import { Toast, Notify, Field, Cell, Search, Icon, Collapse, CollapseItem, Image } from "vant";
    import arrow from '@/assets/img/query/arrow-left.svg';
    import copyrightIp from "@/components/copyrightIp";
    import PopupTips from "./components/PopupTips.vue";
    
    export default {
        components: {
            [Toast.name]: Toast ,
            [Notify.name]: Notify,
            [Field.name]: Field,
            [Cell.name]: Cell,
            [Search.name]: Search,
            [Icon.name]: Icon,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Image.name]: Image,
            copyrightIp,
            PopupTips,
        },
        data() {
            return {
                queryCodeId: '',
                queryType: '',
                arrow,
                imgList: {},
                keywords: '',
                showLimitQuery: false,
                policyList: [],
                tabActive: [],
                id: null,
                typeName: '',
                isSearch: false
            };
        },
        created() {
            const { queryCodeId, queryType } = this.$route.params;
            this.queryCodeId = queryCodeId;
            this.queryType = queryType;
            const map = {
                welfare: "优惠政策"
            };
            document.title = map[queryType];
            // localStorage.setItem( 'querSalaryUserId' , Math.random().toString(36).substring(2, 10));
            const imgFiles = require.context('@/assets/img/query/', false, /-zc.png$/)
            const imgList = [];
            imgFiles.keys().forEach((key) => {
                const fileName = key.replace(/^\.\//, '').replace(/\.png$/, '');
                imgList[fileName] = imgFiles(key);
            })
            this.imgList = imgList;
            if (!validatenull(sessionStorage.getItem('selected'))) {
              const selected = JSON.parse(sessionStorage.getItem('selected'));
              const { id, keywords, typeName } = selected;
              this.keywords = keywords;
              this.typeName = typeName;
              this.id = id;
            }
            this.queryHandler();
        },
        methods: {
          /**
           * 获取列表
           * collapse展开规则
           *  1、keywords不为空，全部展开
           *  2、keywords为空并且点击搜索，全部收起
           *  3、keywords为空且是从详情页返回，展开点击政策所在collapse
           *  4、其他情况全部收起
           * 高亮显示
           *  1、满足collapse第1条，政策名称与关键字名称相同部分高亮
           *  2、如果是从详情页返回并且不是手动点开搜索，高亮整个政策名称
           * @param isSearch 是否手动点击搜索
           * @returns {Promise<void>}
           */
            async queryHandler(isSearch) {
                this.isSearch = isSearch == 1;
                try {
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true
                    });
                    const res = await getSalaryResult({
                        userId: localStorage.getItem( 'querSalaryUserId'),
                        queryFunction: this.queryCodeId,
                        scope: this.keywords,
                        year: new Date().getFullYear()
                    });
                    if (res.data.code == 0) {
                        const resData = res.data.data;
                        this.policyList = resData.filter(item => !validatenull(item.list));
                        const typeNames = this.policyList.map(item => item.typeName);
                        let tabActive = [];
                        if (!validatenull(this.keywords)) {
                          tabActive = typeNames;
                        } else if (isSearch == 1) {
                          tabActive = [];
                        } else if (!validatenull(this.id)) {
                          tabActive = [this.typeName]
                        } else {
                          tabActive = [];
                        }
                        this.tabActive = tabActive;
                    } else {
                        res.data.code == 1 ? this.showLimitQuery = true : Notify(res.data.msg)
                    }
                } catch (error) {
                } finally {
                    Toast.clear();
                }
            },
            // 查询
            handleSearch() {
                this.queryHandler(1);
            },
            // 埋点
            postLog(res) {
                const map = {
                    welfare: "优惠政策"
                }
                postLog({
                    userId: localStorage.getItem("querSalaryUserId"),
                    clickPlace: "查询-" + map[this.queryType],
                    queryResult: res,
                });
            },
            // 获取详情
            async getDetail(val, typeName) {
              const { id, title } = val;
              const userId = localStorage.getItem('querSalaryUserId');
              try {
                  const res = await getWelfareDetail({
                      userId,
                      id
                  });
                  if (res.data.code == 0) {
                      const resData = res.data.data;
                      const result = {
                          ...resData,
                      };
                      this.postLog(`查询成功:${ title }； 查询关键字-${ this.keywords }`);
                      this.querySuccess(result, typeName);
                  } else {
                      res.data.code == 1 ? this.showLimitQuery = true : Notify(res.data.msg)
                      this.postLog('查询失败: '+ res.data.msg)
                  }
              } catch (error) {
                  console.log(error, 'error')
                  this.postLog('查询失败: 未知错误');
              }
            },
            // 跳转到查询结果页
            querySuccess(result, typeName) {
              const { id, title } = result;
              const selected = {
                id,
                keywords: this.keywords,
                typeName
              }
              sessionStorage.setItem("selected", JSON.stringify(selected));
              sessionStorage.setItem("querSalaryResult", JSON.stringify(result));
              sessionStorage.setItem("isShare", false);
              this.$router.push({
                  path: `/salaryQueryv2/result/${id}/${this.queryCodeId}/${this.queryType}`,
              });
            }
        },
        computed: {
          policyListFilter() {
            let policyList = JSON.parse(JSON.stringify(this.policyList));
            policyList.forEach(item => {
              item.list.forEach(subItem => {
                if (subItem.id == this.id && !this.isSearch) {
                
                } else {
                  if (!validatenull(this.keywords) && subItem.title.includes(this.keywords)) {
                    subItem.title = subItem.title.replace(this.keywords, `<span style="background-color: yellow; padding: 2px;">${ this.keywords }</span>`)
                  }
                }
              })
            })
            return policyList;
          }
        }
    };
</script>

<style lang="scss" scoped>
  .QueryRegion {
    width: 100%;
    height: 100%;
    position: relative;
    .QueryRegion_h {
      min-height: calc(100% - 74px);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,0);
    }
    .QueryRegion_bac {
      width: 100%;
      height: 130px;
      background-image: url("../../assets/img/query/bg2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-y: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .QueryRegion_til {
      width: 300px;
      padding: 32px 0;
      margin: auto;
      img {
        text-align: center;
        width: 100%;
      }
    }
    .QueryRegion_box {
      width: 100%;
      padding: 0 10px;
      .QueryRegion_content {
        width: 100%;
        height: 50px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }
    }
    .QueryRegion_data {
      margin-top: 15px;
      border-radius: 10px;
      overflow: hidden;
    }
    ::v-deep .van-search {
      width: 100%;
      height: 100%;
      padding: 0;
      .van-cell {
        font-size: 16px;
      }
      .van-search__content {
        background-color: #fff;
      }
    }
    ::v-deep .van-collapse-item__content {
      padding: 0 0 0 3px;
      .van-cell {
        .van-cell__title {
          position: relative;
          padding-left: 15px;
          color: #666;
          &::before {
            display: inline-block;
            content: '';
            width: 8px;
            height: 8px;
            background-color: #999;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }
    .van-collapse-item__title {
      .van-cell__left-icon {
        margin-right: 10px;
      }
      .van-cell__right-icon {
        &::before {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
    .van-collapse-item__title--expanded {
      .van-cell__right-icon {
        &::before {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
    .collapse-item-title {
      height: 100%;
      display: flex !important;
      align-items: center;
    }
    
  }
</style>